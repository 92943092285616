





























import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import CloseCicleIcon from '@/app/ui/assets/close_circle_icon_modal.vue'

@Component({
  components: {
    Modal,
    Button,
    CloseCicleIcon,
  },
})
export default class ModalCourierNotFound extends Vue {
  @Prop({ default: false }) private visible!: boolean
}
