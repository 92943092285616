var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ModalConfirm',{attrs:{"visible":_vm.modalEditConfirm,"title":"Konfirmasi Perubahan Data Seller","description":"Pastikan perubahan data seller yang diisi sudah sesuai dengan data aslinya","isDisabled":_vm.WhiteSellerController.isLoading},on:{"cancel":function () {
        _vm.modalEditConfirm = false
      },"action":_vm.onSubmit}}),_c('ModalSuccess',{attrs:{"visible":_vm.modalSuccessAdd,"title":"Data Berhasil Diubah","description":"Anda bisa melihat dan mengelola daftar data kurir melalui halaman manage whitelist"},on:{"close":_vm.onBackToList}}),_c('ModalCourierNotFound',{attrs:{"visible":_vm.modalCRRNFD},on:{"cancel":_vm.handleCancelModalCRRNFD,"action":_vm.handleNewCourier}}),_c('h2',{staticClass:"font-bold text-gray-4 mb-8"},[_vm._v("Edit Seller")]),_c('MarketplaceTextInput',{attrs:{"fieldLabel":"Nama Seller","id":"name-seller","name":"nameSeller","phoneCode":_vm.form.phoneCode,"customClass":"py-0.5 pr-14","placeholder":"Masukkan nama seller","required":true,"withCounter":"","maxCount":255,"errorMessage":_vm.$v.form.name.$model.length > _vm.MAX_NAME_WHITE_SELLER
        ? 'Nama seller harus 1 sampai 255 karakter'
        : ''},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('MarketplaceInputPhone',{attrs:{"fieldLabel":"No. Telephone","id":"no-telp","name":"noTelp","placeholder":"81372492141","allowPasteNumber":"","required":true,"errorMessage":!_vm.$v.form.phone.maxLength
        ? 'Nomor telepon harus 10 sampai 13 karakter'
        : ''},on:{"phoneCodeChange":_vm.onPhoneCodeChange},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('MarketplaceDropdown',{attrs:{"fieldLabel":"Status","id":"status-seller","name":"statusSeller","placeholder":"Status Seller","required":true,"options":_vm.statusOptions},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}}),_c('MarketplaceDropdown',{attrs:{"id":"partner-pos","fieldLabel":"Partner POS","required":_vm.partnerPOSRequired,"name":"partnerPOS","placeholder":"Contoh: BUDI JAYA ABADI [SUB-021-224]","options":_vm.partnerPOSOptions},on:{"search":_vm.onSearchPartnerPOS,"input":_vm.onSelectPartnerPOS},model:{value:(_vm.form.agentId),callback:function ($$v) {_vm.$set(_vm.form, "agentId", $$v)},expression:"form.agentId"}}),_c('MarketplaceDropdown',{attrs:{"fieldLabel":"Seller Role","id":"status-seller","name":"sellerRole","placeholder":"Pilih seller role","required":true,"options":_vm.sellerRoleOptions},model:{value:(_vm.form.sellerRole),callback:function ($$v) {_vm.$set(_vm.form, "sellerRole", $$v)},expression:"form.sellerRole"}}),(_vm.showCODHandlingDropdown)?_c('MarketplaceDropdown',{attrs:{"fieldLabel":"COD Handling","id":"cod-handling","name":"codHandling","placeholder":"Pilih tipe COD Handling","options":_vm.codHandlingOptions},model:{value:(_vm.form.codHandling),callback:function ($$v) {_vm.$set(_vm.form, "codHandling", $$v)},expression:"form.codHandling"}}):_vm._e(),_c('Button',{staticClass:"w-full max-w-32-a text-xs mt-5",attrs:{"disabled":_vm.$v.$invalid},on:{"click":function () {
        _vm.modalEditConfirm = true
      }}},[_vm._v(" Submit ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }