


























































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import {
  required,
  maxLength,
  requiredIf,
  ValidationRule,
} from 'vuelidate/lib/validators'
import Button from '@/app/ui/components/Button/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import MarketplaceTextInput from '../../components/MarketplaceTextInput/index.vue'
import MarketplaceDropdown from '../../components/MarketplaceDropdown/index.vue'
import ModalConfirm from '../../components/MarketplaceModal/ModalConfirm.vue'
import ModalSuccess from '../../components/MarketplaceModal/ModalSuccess.vue'
import ModalCourierNotFound from '../../components/MarketplaceModal/ModalCourierNotFound.vue'
import POSController from '@/app/ui/controllers/POSController'
import WhiteSellerController from '@/app/ui/controllers/WhiteSellerController'
import { POS } from '@/domain/entities/POS'
import { LocalStorage, Utils } from '@/app/infrastructures/misc'
import {
  MAX_NAME_WHITE_SELLER,
  MAX_PHONE_NUMBER_WHITE_SELLER,
} from '@/app/infrastructures/misc/Constants/validation'
import { Courier } from '@/domain/entities/Courier'
import MarketplaceInputPhone from '../../components/MarketplaceInputPhone/index.vue'

interface OptionsNumber {
  label: string
  value: number
}

interface OptionsString {
  label: string
  value: string
}

interface IValidations {
  form: {
    name: {
      required: () => ValidationRule
      maxLength: ValidationRule
    }
    phone: {
      required: () => ValidationRule
      maxLength: () => boolean
      minLength: () => boolean
    }
    status: {
      required: () => ValidationRule
    }
    agentId: {
      required: ValidationRule
    }
    sellerRole: {
      required: () => ValidationRule
    }
  }
}

interface WhiteSeller {
  id: number
  name: string
  phone: string
  status: string
  partnerId: number
  partnerPOS: string
  role: string
  codHandling: string
}

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    DropdownSelect,
    MarketplaceTextInput,
    MarketplaceDropdown,
    ModalConfirm,
    ModalSuccess,
    ModalCourierNotFound,
    MarketplaceInputPhone,
  },
})
export default class EditSellerWhitelist extends Vue {
  POSController = POSController
  WhiteSellerController = WhiteSellerController
  modalEditConfirm = false
  modalSuccessAdd = false
  modalCRRNFD = false
  statusOptions = [
    { label: 'Aktif', value: 'ACTIVE' },
    { label: 'Tidak Aktif', value: 'INACTIVE' },
  ]
  sellerRoleOptions = [
    { label: 'TOKOPEDIA', value: 'TOKOPEDIA' },
    { label: 'BUKALAPAK', value: 'BUKALAPAK' },
    { label: 'MERCHANT', value: 'MERCHANT' },
    { label: 'CLIENT', value: 'CLIENT' },
  ]
  codHandlingOptions = [
    {
      label: 'specialcod',
      value: 'specialcod',
    },
    {
      label: 'standardcod',
      value: 'standardcod',
    },
  ]
  partnerPOSOptions = <OptionsNumber[]>[]
  form = {
    id: 0,
    name: '',
    phone: '',
    status: this.statusOptions[0],
    agentId: [] as Record<string, any>,
    sellerRole: [] as Record<string, any>,
    codHandling: [] as Record<string, any>,
    phoneCode: '',
  }
  currentPartnerId = 0
  partnerPOSRequired = true
  parameters = {
    page: 1,
    per_page: 100,
    search: '',
    sort_by: 'ASC',
  }
  MAX_PHONE_NUMBER_WHITE_SELLER = MAX_PHONE_NUMBER_WHITE_SELLER
  MAX_NAME_WHITE_SELLER = MAX_NAME_WHITE_SELLER

  created(): void {
    this.fetchData()
  }

  get myAccessMenus(): string[] {
    return JSON.parse(
      LocalStorage.getLocalStorage(LocalStorage.ACCESS_MENU, true)
    )
  }

  get showCODHandlingDropdown(): boolean {
    return this.form.sellerRole.value === 'CLIENT'
  }

  private async fetchData(posOnly?: boolean) {
    await POSController.getPOSData(this.parameters)
    if (!posOnly) {
      WhiteSellerController.getDetail(this.$route.params.id)
    }
  }

  private onSearchPartnerPOS = Utils.debounce((keyword: string) => {
    if (keyword) {
      POSController.getPOSData({
        ...this.parameters,
        search: keyword,
      })
    }
  }, 500)

  private onSelectPartnerPOS(pos: OptionsNumber) {
    if (pos) POSController.getCourierList(String(pos.value))
  }

  private handleCancelModalCRRNFD() {
    this.modalCRRNFD = false
    this.form.agentId = []
  }

  private handleNewCourier() {
    if (this.myAccessMenus && this.myAccessMenus.includes('manage-courier')) {
      let routeData = this.$router.resolve({ name: 'CreateManageCourierPage' })
      window.open(routeData.href, '_blank')
    } else {
      this.form.agentId = []
      this.$notify({
        title: 'Forbidden Access',
        text: 'Please request access to admin',
        type: 'error',
        duration: 3000,
      })
      this.modalCRRNFD = false
    }
  }

  private onBackToList() {
    this.$router.push({ name: 'ManageWhitelist' })
  }

  private onSubmit() {
    const payload = {
      id: this.form.id,
      name: this.form.name,
      phone: `${this.form.phoneCode}${this.form.phone}`,
      status: this.form.status.value,
      agentId: this.form.agentId && this.form.agentId.value,
      role: this.form.sellerRole.value,
      codHandling: this.form.codHandling?.value,
    }

    if (this.form.sellerRole.value !== 'CLIENT') {
      delete payload.codHandling
    }

    WhiteSellerController.updateWhiteSeller(payload)
  }

  private onPhoneCodeChange(phoneCode: string) {
    this.form.phoneCode = phoneCode
  }

  private maxLengthIndonesianPhoneNumber(): boolean {
    if (this.form.phoneCode === '+62') {
      return this.form.phone.length <= 13
    }
    return true
  }

  private minLengthIndosianPhoneNumber(): boolean {
    if (this.form.phoneCode === '+62') {
      return this.form.phone.length >= 9
    }
    return true
  }

  @Validations()
  validations(): IValidations {
    const isAgentIdRequired = this.partnerPOSRequired
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(MAX_NAME_WHITE_SELLER),
        },
        phone: {
          required,
          maxLength: this.maxLengthIndonesianPhoneNumber,
          minLength: this.minLengthIndosianPhoneNumber,
        },
        status: { required },
        agentId: {
          required: requiredIf(function() {
            return isAgentIdRequired
          }),
        },
        sellerRole: { required },
      },
    }
  }

  @Watch('POSController.posData')
  setPOSPartner(data: POS[]): void {
    const POSOptions = data.map(item => {
      return {
        label: `${item.name} [${item.code}]`,
        value: item.id,
      }
    }) as OptionsNumber[]
    this.partnerPOSOptions = POSOptions
    const currentPOS = <OptionsNumber>(
      POSOptions.find(item => item.value === this.currentPartnerId)
    )
    this.form.agentId = currentPOS
    this.onSelectPartnerPOS(currentPOS)
  }

  @Watch('POSController.posCourier')
  setPOSCourier(data: Courier[]): void {
    if (data && data.length === 0) {
      this.modalCRRNFD = true
    }
  }

  @Watch('WhiteSellerController.dataWhiteSeller')
  updateForm(val: WhiteSeller): void {
    if (val) {
      // Set Partner POS
      this.parameters = {
        ...this.parameters,
        search: <string>val.partnerPOS,
      }
      this.currentPartnerId = <number>val.partnerId
      this.fetchData(true)

      this.form = {
        id: <number>val.id,
        name: <string>val.name || '',
        phone: <string>val.phone,
        status: <OptionsString>(
          this.statusOptions.find(item => item.value === val.status)
        ),
        agentId: this.partnerPOSOptions.filter(
          item => item.value === val.partnerId
        ),
        sellerRole:
          this.sellerRoleOptions.find(item => item.label === val.role) || [],
        codHandling:
          this.codHandlingOptions.find(
            item => item.value === val.codHandling
          ) || [],
        phoneCode: '',
      }
    }
  }

  @Watch('form.status')
  onStatusChange(status: OptionsString): void {
    // Set Partner POS Required
    if (status.value === 'ACTIVE') {
      this.partnerPOSRequired = true
    } else {
      this.partnerPOSRequired = false
    }
  }

  @Watch('WhiteSellerController.isEditWhiteSellerSuccess')
  onEditWhiteSellerSuccess(val: boolean): void {
    if (val) {
      this.modalEditConfirm = false
      this.modalSuccessAdd = val
    }
  }

  @Watch('form.name')
  setSellerName(data: string): void {
    // remove script tag
    this.form.name = Utils.removeScriptTags(data)

    // remove special character
    this.form.name = Utils.removeSpecialCharacters(this.form.name)

    // remove html tag
    this.form.name = Utils.removeHTMLTags(this.form.name)
  }

  beforeDestroy(): void {
    this.WhiteSellerController.setIsEditWhiteSellerSuccess(false)
  }
}
